import projets from "./assets/data/projets.json";

const addProjects = projects => {
  const visuelweb = document.querySelector("section.visuelweb");

  projects.forEach(project => {
    let projectName = project.name;
    let projectCourse = project.cours;
    let projectImg = project.imgSrc;
    let projectDescriptions = project.description;
    let projectURL = project.url;

    let projectCard = document.createElement("card_section");
    let projectCardHeader = document.createElement("card_header");

    projectCardHeader.innerHTML = `<h4>${projectName}</h4>`;
    projectCardHeader.innerHTML += `<h5>${projectCourse}</h5>`;
    projectCardHeader.innerHTML += `<h6>${projectDescriptions}</h6>`;

    projectCard.appendChild(projectCardHeader);

    // Utilisez directement l'élément de lien (<a>) autour de l'image pour l'ouvrir dans un nouvel onglet
    projectCard.innerHTML += `<a href="${projectURL}" target="_blank"><img src="${projectImg}" class="project-image"/></a>`;

    let viewProjectButton = document.createElement("button");
    viewProjectButton.innerText = "Voir le projet";
    viewProjectButton.classList.add("bouton_card");

    // Ajoutez un écouteur d'événements au bouton pour ouvrir l'URL dans un nouvel onglet
    viewProjectButton.addEventListener("click", () => {
      window.open(projectURL, "_blank");
    });

    projectCard.appendChild(viewProjectButton);

    visuelweb.appendChild(projectCard);
  });
};

addProjects(projets);
