[
  {
    "name": "Infolettre",
    "cours": "Design graphique appliqué 1",
    "description": "Réalisation d'une infolettre - Photoshop - InDesign",
    "imgSrc": "/static/assets/img/Infolettre.png",
    "url": "/static/assets/img/Infolettre2.png"
  },
  {
    "name": "Incubateur A2",
    "cours": "Conception Multimédia",
    "description": "Concevoir un produit Web - Photoshop - InDesign",
    "imgSrc": "/static/assets/img/A2.png",
    "url": "/static/assets/img/SiteWeb_A2.png"
  },

  {
    "name": "Interface de produits",
    "cours": "Design graphique appliqué 2",
    "description": "Maquette filaire et processus de conception  - Figma",
    "imgSrc": "/static/assets/img/Autour_the.png",
    "url": "/static/assets/img/WireframeAT.png"
  },

  {
    "name": "Les fougères du Québec",
    "cours": "Création de page Web",
    "description": "Utilisation de la balise figure - HTML - CSS",
    "imgSrc": "/static/assets/img/FougereAtelier1.png",
    "url": "/static/assets/img/FougereAtelier.png"
  },
  {
    "name": "Chat Alors!",
    "cours": "Réalisation d'un site Web",
    "description": "Les grilles flexibles - HTML - CSS",
    "imgSrc": "/static/assets/img/Chats1SiteWeb.png",
    "url": "/static/assets/img/ChatsSiteWeb.png"
  },

  {
    "name": "CLEF Mitis-Neigette",
    "cours": "Projet Medias Interactifs",
    "description": "Refonte d'un site Web - Wordpress ",
    "imgSrc": "/static/assets/img/Clef_metis.png",
    "url": "/static/assets/img/ClefAccueil.png"
  }
]
